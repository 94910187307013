<template>
  <div class="commission">
    <HomeHeader />
    <div class="img">
      <img src="@/assets/images/pc/Inside/Inside-pc-5-bg.png" alt="" />
    </div>
    <div class="table">
      <div class="btn-box">
        <div
          class="btn"
          v-for="item in tabListTable"
          :key="item.id"
          @click="getUrlImg(item.value)"
        ></div>
      </div>
      <!-- 
        取消计算属性使用v-show 频繁切换问题
        <img :src="getUrl" alt="" /> -->
      <img
        v-show="imgUrl === 1"
        src="@/assets/images/pc/Inside/Inside-pc-table1.png"
        alt=""
      />
      <img
        v-show="imgUrl === 2"
        src="@/assets/images/pc/Inside/Inside-pc-table2.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import HomeHeader from "@/components/pc/HomeHeader";
import elementHeight from "@/mixins/pc/elementHeight";

export default {
  name: "Commission",
  mixins: [elementHeight],
  data() {
    return {
      imgUrl: 1,
      tabListTable: [
        {
          id: 1,
          value: 1,
        },
        {
          id: 2,
          value: 2,
        },
      ],
    };
  },
  computed: {
    // getUrl() {
    //   return require(`@/assets/images/pc/Inside/Inside-pc-table${this.imgUrl}.png`);
    // },
  },
  methods: {
    getUrlImg(url) {
      this.imgUrl = url;
    },
  },
  components: {
    HomeHeader,
  },
};
</script>

<style lang="less" scoped>
.commission {
  font-size: 16px;

  img {
    width: 100%;
  }
  .table {
    .btn-box {
      display: flex;
      padding: 0 10px;

      width: 60%;
      margin: auto;
      .btn {
        width: 50%;
        height: 0.7rem;
        border-radius: 10px;
        z-index: 1;
        margin-bottom: -1rem;
      }
    }
    margin-top: -18.6rem;
    img {
      width: 60%;
      margin: auto;
    }
  }
}
</style>
